(function() {
	"use strict";

	angular
		.module("smartermail")
		.service("externalEmailService", externalEmailService);

	function externalEmailService($http, $q, $rootScope, $translate, coreDataSettings) {
		var vm = this;
		vm.accountTypes = [
			{
				id: 14,
				name: "SMARTERMAIL",
				icon: "smartermail.png",
				test: testConnectionExchange,
				migrate: startMigrationSmarterMail,
				hideConnectionDetails: true,
				contacts: true,
				calendars: true,
				tasks: true,
				notes: true,
				forceType: "EWS"
			},
			{
				id: 1,
				name: "PRODUCTS_MICROSOFT_EXCHANGE",
				icon: "microsoft-exchange.png",
				test: testConnectionExchange,
				migrate: startMigrationExchange,
				hideConnectionDetails: true,
				contacts: true,
				calendars: true,
				tasks: true,
				notes: true,
				showDomain: false,
				forceType: "EWS"
			},
			{
				id: 5,
				name: "PRODUCTS_OUTLOOK_COM",
				icon: "outlook-dot-com.gif",
				hideConnectionDetails: true,
				forceSSL: true,
				usernameIsEmail: true,
				presetServer: "outlook.office365.com",
				presetPort: 993,
				forceType: "IMAP"
			},
			{
				id: 15,
				name: "PRODUCTS_MICROSOFT_365",
				icon: "microsoft-logo.png",
				test: testConnectionExchange,
				migrate: startMigrationExchange,
				hideConnectionDetails: true,
				contacts: true,
				calendars: true,
				tasks: true,
				notes: true,
				forceSSL: true,
				usernameIsEmail: true,
				presetServer: "outlook.office365.com",
				forceType: "EWS"
			},
			{
				id: 3,
				name: "PRODUCTS_GMAIL",
				icon: "gmail.png",
				hideConnectionDetails: true,
				forceSSL: true,
				hideServerAddress: true,
				presetServer: "imap.gmail.com",
				presetPort: 993,
				forceType: "IMAP"
			},
			{ id: 4, name: "PRODUCTS_YAHOO_MAIL", icon: "yahoo-mail.png" },
			{ id: 6, name: "PRODUCTS_ZIMBRA", icon: "zimbra.png", contacts: false,
				calendars: false,
				tasks: false,
				notes: false, },
			{ id: 7, name: "PRODUCTS_OPEN_XCHANGE", icon: "open-xchange.png" },
			{
				id: 8,
				name: "PRODUCTS_KERIO_CONNECT",
				icon: "kerio-connect.png",
				test: testConnectionExchange,
				migrate: startMigrationExchange,
				hideConnectionDetails: true,
				contacts: true,
				calendars: true,
				tasks: true,
				notes: true,
				showDomain: false,
				forceType: "EWS"
			},
			{ id: 9, name: "PRODUCTS_COMMUNIGATE", icon: "communigate.png" },
			{ id: 10, name: "PRODUCTS_ICEWARP", icon: "icewarp.png" },
			{ id: 11, name: "PRODUCTS_MDAEMON", icon: "mdaemon.jpg" },
			{ id: 12, name: "PRODUCTS_PROGRESS_IMAIL", icon: "progress.png" },
			{ id: 13, name: "PRODUCTS_MAILENABLE", icon: "mailenable.png" },
			{ id: 0, name: "OTHER", icon: "generic-email.png" }
		];
		vm.migrationSpinningUp = false;

		vm.startMigration = startMigration;
		vm.startRetrieval = startRetrieval;
		vm.testConnection = testConnection;

		activate();

		///////////////////////////

		function activate() {
			refreshItems();
			$rootScope.$on("$translateChangeSuccess", refreshItems);
		}

		function refreshItems() {
			angular.forEach(vm.accountTypes, function (value) { value.translated = $translate.instant(value.name); });
		}

		function startMigration(account) {
			const defer = $q.defer();
			$rootScope.$broadcast("migrationStarting");
			vm.migrationSpinningUp = true;

			let whatToImport = 0;
			if (account.migrateEmail)
				whatToImport |= 1;
			if (account.migrateContacts)
				whatToImport |= 1 << 1;
			if (account.migrateCalendars)
				whatToImport |= 1 << 2;
			if (account.migrateTasks)
				whatToImport |= 1 << 3;
			if (account.migrateNotes)
				whatToImport |= 1 << 4;
			
			const func = account.selectedAccountType.migrate ||
				(account.accountType === "EWS"
					? startMigrationExchange
					: account.accountType === "IMAP"
						? startMigrationImap
						: startMigrationPop);

			func(account, whatToImport)
				.then(
					function(result) {
						vm.migrationSpinningUp = false;
						coreDataSettings.migrationID = result.data.id;
						$rootScope.$broadcast("migrationStarted");
						defer.resolve({ success: true, migrationID: result.data.id });
					},
					function(result) {
						vm.migrationSpinningUp = false;
						$rootScope.$broadcast("migrationError", { message: "MAILBOXMIGRATION_SIZE_FAILED" });
						defer.reject({ success: false, message: $translate.instant("MAILBOXMIGRATION_SIZE_FAILED") + result.data.message });
					});

			return defer.promise;
		}

		function startMigrationExchange(account, whatToImport, isSmMigration) {
			const defer = $q.defer();

			const params = JSON.stringify({
				exchangeAccount: {
					serverAddress: account.serverAddress,
					emailAddress: account.username,
					password: account.password,
					serverPort: 80,
					encryption: account.encryption,
					enableSpamFilter: false,
					isManualRetrieval: true,
					itemsToImport: whatToImport,
					useOnlyOnce: true,
					userDisplayed: false,
					downloadToFolder: "",
					isMailboxMigration: true,
					deleteEverythingBeforeImport: account.deleteEverythingBeforeImport,
					accountTypeDescription: account.selectedAccountType.name === "PRODUCTS_ZIMBRA" ?  "Other" : (isSmMigration ? "SmarterMail" : "Exchange"),
					oauthAccount: account.oauthAccount,
					oauthAccessToken: account.oauthAccessToken,
					oauthRefreshToken: account.oauthRefreshToken,
					oauthTokenExpires: account.oauthTokenExpires
				}
			});

			// Size Test
			$http.post("~/api/v1/settings/exchange-migration-size", params)
				.then(function (result) {
					if (!result.data.success || !result.data.hasEnoughSize)
						defer.reject(result);
					else
						$http.post("~/api/v1/settings/exchange-migration", params).then(defer.resolve, defer.reject);
				}, defer.reject);

			return defer.promise;
		}

		function startMigrationImap(account, whatToImport) {
			const defer = $q.defer();

			const imapAccount = {
				serverAddress: account.serverAddress,
				username: account.username,
				password: account.password,
				serverPort: account.serverPort,
				encryption: account.encryption,
				enableSpamFilter: false,
				isManualRetrieval: true,
				accountType: account.accountType,
				useOnlyOnce: true,
				userDisplayed: false,
				accountTypeDescription: "Other",
				itemsToImport: whatToImport,
				isMailboxMigration: true,
				deleteEverythingBeforeImport: account.deleteEverythingBeforeImport,
				bypassSslErrors: account.bypassSSLErrors,
				oauthAccount: account.oauthAccount,
				oauthAccessToken: account.oauthAccessToken,
				oauthRefreshToken: account.oauthRefreshToken,
				oauthTokenExpires: account.oauthTokenExpires
			};
			let params = JSON.stringify({ imapAccount: imapAccount });

			// Size Test
			$http.post("~/api/v1/settings/imap-migration-size", params)
				.then(function (result) {
					if (!result.data.success || !result.data.hasEnoughSize)
						defer.reject(result);
					else {
						imapAccount.oauthAccount = imapAccount.oauthAccount || result.data.oauthAccount;
						imapAccount.oauthAccessToken = imapAccount.oauthAccessToken || result.data.oauthAccessToken;
						imapAccount.oauthRefreshToken = imapAccount.oauthRefreshToken || result.data.oauthRefreshToken;
						imapAccount.oauthTokenExpires = imapAccount.oauthTokenExpires || result.data.oauthTokenExpires;

						params = JSON.stringify({ imapAccount: imapAccount });
						$http.post("~/api/v1/settings/imap-migration", params).then(defer.resolve, defer.reject);
					}
				}, defer.reject);

			return defer.promise;
		}

		function startMigrationPop(account, whatToImport) {
			const defer = $q.defer();

			const popAccount = {
					serverAddress: account.serverAddress,
					username: account.username,
					password: account.password,
					serverPort: account.serverPort,
					encryption: account.encryption,
					enableSpamFilter: false,
					isManualRetrieval: true,
					leaveMailOnServer: true,
					accountType: account.accountType,
					useOnlyOnce: true,
					userDisplayed: false,
					accountTypeDescription: "Other",
					itemsToImport: whatToImport,
					isMailboxMigration: true,
					deleteEverythingBeforeImport: account.deleteEverythingBeforeImport,
					bypassSslErrors: account.bypassSSLErrors,
					oauthAccount: account.oauthAccount,
					oauthAccessToken: account.oauthAccessToken,
					oauthRefreshToken: account.oauthRefreshToken,
					oauthTokenExpires: account.oauthTokenExpires
				};
			let params = JSON.stringify({ popAccount: popAccount });

			// Size Test
			$http.post("~/api/v1/settings/pop-migration-size", params)
				.then(function (result) {
					if (!result.data.success || !result.data.hasEnoughSize)
						defer.reject(result);
					else {
						popAccount.oauthAccount = popAccount.oauthAccount || result.data.oauthAccount;
						popAccount.oauthAccessToken = popAccount.oauthAccessToken || result.data.oauthAccessToken;
						popAccount.oauthRefreshToken = popAccount.oauthRefreshToken || result.data.oauthRefreshToken;
						popAccount.oauthTokenExpires = popAccount.oauthTokenExpires || result.data.oauthTokenExpires;

						params = JSON.stringify({ popAccount: popAccount });
						$http.post("~/api/v1/settings/pop-migration", params).then(defer.resolve, defer.reject);
					}
				}, defer.reject);

			return defer.promise;
		}

		function startMigrationSmarterMail(account, whatToImport) {
			return startMigrationExchange(account, whatToImport, true);
		}

		function startRetrieval(account) {
			const func = account.accountType === "EWS"
				? startRetrievalExchange
				: account.accountType === "IMAP"
					? startRetrievalImap
					: startRetrievalPop;

			return func(account.id);
		}

		function startRetrievalExchange(accountId) {
			return $http.post(`~/api/v1/settings/retrieve-exchange/${accountId}`);
		}

		function startRetrievalImap(accountId) {
			return $http.post(`~/api/v1/settings/retrieve-imap/${accountId}`);
		}

		function startRetrievalPop(accountId) {
			return $http.post(`~/api/v1/settings/retrieve-pop/${accountId}`);
		}
		
		function testConnection(account, isMigration) {
			const testMethod = account.selectedAccountType.test ||
				(account.accountType === "EWS"
					? testConnectionExchange
					: account.accountType === "IMAP"
						? testConnectionImap
						: testConnectionPop);
			return testMethod(account, isMigration);
		}

		function testConnectionExchange(account, isMigration) {
			var params = JSON.stringify({
				exchangeAccount: {
					serverAddress: account.serverAddress,
					emailAddress: account.username,
					password: account.password,
					serverPort: account.serverPort,
					encryption: account.encryption,
					isMailboxMigration: isMigration,
					oauthCode: account.oauthCode
				}
			});

			return $http.post("~/api/v1/settings/exchange-test-connection", params);
		}

		function testConnectionImap(account, isMigration) {
			const params = {
				imapAccount: {
					serverAddress: account.serverAddress,
					username: account.username,
					password: account.password,
					serverPort: account.serverPort,
					encryption: account.encryption,
					deleteEverythingBeforeImport: account.deleteEverythingBeforeImport,
					downloadToFolder: account.downloadToFolder,
					enableSpamFilter: account.enableSpamFilter,
					isManualRetrieval: account.isManualRetrieval,
					folderTransferMethod: account.folderTransferMethod,
					leaveMailOnServer: account.leaveMailOnServer,
					useApop: account.useApop,
					accountType: account.accountType,
					isMailboxMigration: isMigration,
					bypassSslErrors: account.bypassSSLErrors,
					oauthCode: account.oauthCode
				}
			};
			
			return $http.post("~/api/v1/settings/imap-test-connection", params);
		}

		function testConnectionPop(account, isMigration) {
			const params = {
				popAccount: {
					serverAddress: account.serverAddress,
					username: account.username,
					password: account.password,
					serverPort: account.serverPort,
					ID: account.ID,
					encryption: account.encryption,
					deleteEverythingBeforeImport: account.deleteEverythingBeforeImport,
					downloadToFolder: account.downloadToFolder,
					enableSpamFilter: account.enableSpamFilter,
					isManualRetrieval: account.isManualRetrieval,
					folderTransferMethod: account.folderTransferMethod,
					leaveMailOnServer: account.leaveMailOnServer,
					useApop: account.useApop,
					accountType: account.accountType,
					isMailboxMigration: isMigration,
					bypassSslErrors: account.bypassSSLErrors,
					oauthCode: account.oauthCode
				}
			};
			
			return $http.post("~/api/v1/settings/pop-test-connection", params);
		}
	}
})();