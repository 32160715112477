(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("externalEmailDialogController", externalEmailDialogController);

	function externalEmailDialogController($http, $q, $rootScope, $scope, $mdDialog, $timeout, $window, $translate, coreData,
		errorHandling, externalEmailService, popupService, successHandling, account, isMigration) {

		const microsoftUrls = [
			"outlook.office365.com", // M365, Outlook, Hotmail, Live.com
			"imap-mail.outlook.com", // MSN
			"pop-mail.outlook.com"
		];

		// Properties
		$scope.accountTypes = externalEmailService.accountTypes;
		$scope.defaultAccount = {
			accountType: 'POP',
			deleteEverythingBeforeImport: false,
			domain: '',
			isManualRetrieval: true,
			migrateEmail: false,
			migrateContacts: false,
			migrateCalendars: false,
			migrateTasks: false,
			migrateNotes: false,
			password: '',
			selectedAccountType: null,
			serverAddress: '',
			serverPort: 110,
			username: '',
			encryption: 0,
			get useSsl() { return this.encryption === 1; },
			set useSsl (value) { this.encryption = value ? 1 : 0; }
		};

		$scope.account = $.extend($scope.defaultAccount, account);
		$scope.edit = edit;
		$scope.folders = [];
		$scope.isMigration = isMigration;
		$scope.mustTestConnection = true;
		$scope.selectedFolder = null;
		$scope.sslAuthenticationFailed = false;
		$scope.startingMigration = false;
		$scope.step = 1;
		$scope.testFailure = null;
		$scope.testingConnection = false;
		$scope.testSuccess = false;

		// Functions
		$scope.backStep = backStep;
		$scope.chooseAccountType = chooseAccountType;
		$scope.closeDialog = function () { $mdDialog.cancel(); };
		$scope.deleteAccount = function () { $mdDialog.hide({ deleteItem: true }); };
		$scope.onAccountTypeChanged = onAccountTypeChanged;
		$scope.onServerChanged = onServerChanged;
		$scope.onUseSslChanged = onUseSslChanged;
		$scope.retrieveMessages = retrieveMessages;
		$scope.saveRetrieval = saveRetrieval;
		$scope.startMigration = startMigration;

		activate();

		function activate() {
			$scope.mode = $scope.account.id && !$scope.isMigration ? "view" : "edit";

			if ($scope.isMigration)
				return;
			getSelectedAccountTypeForEdit();

			$scope.account.downloadToFolder = $scope.account.downloadToFolder || "Inbox";
			$scope.account.folderTransferMethod = $scope.account.folderTransferMethod ? $scope.account.folderTransferMethod.toUpperCase() : "ROOTFOLDERS";

			$rootScope.spinner.show();

			const promises = [];
			promises.push($http.get("~/api/v1/settings/list-folders/false"));
			if ($scope.mode === "view") {
				$scope.disabled = true;
				promises.push($http.get(`~/api/v1/settings/retrieval-account-status/${account.id}`));
			}
			$q.all(promises)
				.then(onLoadSuccess, onLoadFailure)
				.finally($rootScope.spinner.hide);

			function onLoadSuccess(result) {
				$scope.folders = angular.copy(result[0].data.folders);
				$scope.selectedFolder = $scope.folders[0];

				if ($scope.account.downloadToFolder) {
					let foundName = false;
					for (let i = 0; i < $scope.folders.length; i++) {
						if ($scope.folders[i].name === $scope.account.downloadToFolder) {
							foundName = true;
							break;
						}
					}
					if (!foundName)
						$scope.folders.push({
							folderResourceID: "",
							translatePath: $scope.account.downloadToFolder,
							name: $scope.account.downloadToFolder
						});
				}

				if (result.length === 1)
					return;
				
				$scope.disabled = result[1].data.status.toLowerCase() === "disabled";
			}

			function onLoadFailure(failure) {
				errorHandling.report(failure);
				$mdDialog.cancel();
			}
		}

		function backStep() {
			if ((!$scope.isMigration && $scope.account.id) || $scope.mode !== "edit")
				return;

			if ($scope.step === 2) {
				$scope.step = 1;
				$scope.testFailure = null;
				$scope.account = $scope.defaultAccount;
				$scope.account.selectedAccountType.contacts = false;
				$scope.account.selectedAccountType.calendars = false;
				$scope.account.selectedAccountType.tasks = false;
				$scope.account.selectedAccountType.notes = false;
			}
		}

		function chooseAccountType(type) {
			if (type.forceSSL) {
				$scope.account.encryption = 1;
			}
			$scope.account.selectedAccountType = type;
			$scope.account.serverPort = type.presetPort || 110;
			$scope.account.accountType = type.forceType || "POP";
			$scope.account.migrateEmail = true;
			$scope.account.hideConnectionDetails = !!type.hideConnectionDetails;
			$scope.account.migrateContacts = !!type.contacts;
			$scope.account.migrateCalendars = !!type.calendars;
			$scope.account.migrateTasks = !!type.tasks;
			$scope.account.migrateNotes = !!type.notes;
			$scope.account.serverAddress = type.presetServer;
			$scope.step = 2;

			onServerChanged();
		}

		function doMicrosoftOAuth() {
			if (!$scope.account.useOAuth)
				return $q.when();

			const defer = $q.defer();

			const newWindow = window.open("", "_blank", popupService.dimensions.connectivity);

			const params = JSON.stringify({ redirectUri: `${coreData.baseUrl}/interface/connect-redirect`});
			$http.post(`~/api/v1/settings/microsoft-redirect/${$scope.account.accountType}`, params)
				.then(
					function (success) {
						$window.handleConnectClose = onConnectClose;
						newWindow.location = success.data.result;
					},
					function (failure) {
						errorHandling.report(failure);
						newWindow.close();
						defer.reject(failure);
					});

			return defer.promise;

			function onConnectClose(result) {
				$window.handleConnectClose = null;
				if (result.code) {
					$scope.account.oauthCode = result.code;
				}
				if (result.error) {
					defer.reject(result.error);
					return;
				}
				defer.resolve();
			}
		}

		function edit() {
			$scope.mode = "edit";
			$scope.step = 2;
			onServerChanged();
		}

		function getSelectedAccountTypeForEdit() {
			if (!$scope.account.id || $scope.ismigration)
				return;

			if ($scope.account.accountTypeDescription) {

				for (let i = 0; i < externalEmailService.accountTypes.length; i++) {
					if (externalEmailService.accountTypes[i].name === $scope.account.accountTypeDescription) {
						$scope.account.selectedAccountType = externalEmailService.accountTypes[i];
						break;
					}
				}
			} else {
				let findId = 0;
				switch ($scope.account.accountType.toUpperCase()) {
				case "EWS":
					findId = microsoftUrls[0] === $scope.account.serverAddress.toLowerCase() ? 15 : 1;
					break;
				case "IMAP":
					if (microsoftUrls.indexOf($scope.account.serverAddress.toLowerCase()) > -1)
						findId = 5;
					break;
				case "POP":
					// No special cases to search for.
					break;
				}

				for (let i = 0; i < externalEmailService.accountTypes.length; i++) {
					if (externalEmailService.accountTypes[i].id === findId) {
						$scope.account.selectedAccountType = externalEmailService.accountTypes[i];
						break;
					}
				}
			}
		}

		function onAccountTypeChanged() {
			$scope.mustTestConnection = true;
			if (!$scope.account.accountType)
				return;

			const port = parseInt($scope.account.serverPort, 10);

			if ($scope.account.accountType === "IMAP"){
				$scope.account.selectedAccountType.contacts = false;
				$scope.account.selectedAccountType.calendars = false;
				$scope.account.selectedAccountType.tasks = false;
				$scope.account.selectedAccountType.notes = false;
				$scope.account.migrateContacts = false;
				$scope.account.migrateCalendars = false;
				$scope.account.migrateTasks = false;
				$scope.account.migrateNotes = false;
				if (port === 110 || port === 995 || port === 443)
					$scope.account.serverPort = $scope.account.useSsl ? 993 : 143;
			}
			if ($scope.account.accountType === "POP"){
				$scope.account.selectedAccountType.contacts = false;
				$scope.account.selectedAccountType.calendars = false;
				$scope.account.selectedAccountType.tasks = false;
				$scope.account.selectedAccountType.notes = false;
				$scope.account.migrateContacts = false;
				$scope.account.migrateCalendars = false;
				$scope.account.migrateTasks = false;
				$scope.account.migrateNotes = false;
				if (port === 143 || port === 993 || port === 443)
					$scope.account.serverPort = $scope.account.useSsl ? 995 : 110;
			}
			if ($scope.account.accountType === "EWS") {
				$scope.account.selectedAccountType.contacts = true;
				$scope.account.selectedAccountType.calendars = true;
				$scope.account.selectedAccountType.tasks = true;
				$scope.account.selectedAccountType.notes = true;
				$scope.account.migrateContacts = true;
				$scope.account.migrateCalendars = true;
				$scope.account.migrateTasks = true;
				$scope.account.migrateNotes = true;
				$scope.account.useSsl = true;
				if (port === 143 || port === 993 || port === 110 || port === 995)
					$scope.account.serverPort = 443;
			}
				
		}

		function onServerChanged() {
			if (!$scope.account.serverAddress || microsoftUrls.indexOf($scope.account.serverAddress.toLowerCase()) === -1) {
				$scope.account.useOAuth = false;
				$scope.account.oauthCode = null;
			} else {
				$scope.account.useOAuth = true;
				$scope.account.useSsl = true;
				onUseSslChanged();
			}
		}

		function onUseSslChanged() {
			$scope.mustTestConnection = true;
			if (!$scope.account.accountType)
				return;

			const port = parseInt($scope.account.serverPort, 10);

			if ($scope.account.useSsl && (port === 110 || port === 143)) {
				if ($scope.account.accountType === "IMAP")
					$scope.account.serverPort = 993;
				else if ($scope.account.accountType === "POP")
					$scope.account.serverPort = 995;
			}
			if (!$scope.account.useSsl && (port === 993 || port === 995)) {
				if ($scope.account.accountType === "IMAP")
					$scope.account.serverPort = 143;
				else if ($scope.account.accountType === "POP")
					$scope.account.serverPort = 110;
			}
		}

		function retrieveMessages() {
			externalEmailService.startRetrieval($scope.account)
				.then(
					function () {
						$mdDialog.hide({ processing: true })
						successHandling.report("RETRIEVE_PROCESS_RUNNING");
					},
					errorHandling.report);
		}

		function saveRetrieval(form) {
			if ($scope.isMigration || $scope.mode !== "edit" || !form.$valid)
				return;

			doMicrosoftOAuth()
				.then(
					function () {
						testConnection(form)
							.then(
								function (result) {
									if ($scope.account.folderTransferMethod === "ROOTFOLDERS" && $scope.account.accountType !== "POP") {
										$scope.account.folderTransferMethod = "RootFolders";
										$scope.account.downloadToFolder = "";
									}

									if (!result.success || !result.testSuccessful)
										return;

									$mdDialog.hide({
										serverAddress: $scope.account.serverAddress,
										username: $scope.account.username,
										password: $scope.account.password,
										serverPort: $scope.account.serverPort,
										encryption: $scope.account.encryption,
										deleteEverythingBeforeImport: $scope.account.deleteEverythingBeforeImport,
										downloadToFolder: $scope.account.downloadToFolder,
										enableSpamFilter: $scope.account.enableSpamFilter,
										isManualRetrieval: $scope.account.isManualRetrieval,
										folderTransferMethod: $scope.account.folderTransferMethod,
										leaveMailOnServer: $scope.account.leaveMailOnServer,
										useApop: $scope.account.useApop,
										accountType: $scope.account.accountType,
										accountTypeDescription: $scope.account.selectedAccountType.name,
										id: $scope.account.id,
										oauthAccount: result.oauthAccount,
										oauthAccessToken: result.oauthAccessToken,
										oauthRefreshToken: result.oauthRefreshToken,
										oauthTokenExpires: result.oauthTokenExpires,
										itemsToImport: 1	// Retrieval only does email
									});
								},
								function () { });
					},
					errorHandling.report);
		}

		function startMigration(form, bypassSslErrors) {
			if (!$scope.isMigration || !form.$valid)
				return;
			$scope.account.bypassSslErrors = bypassSslErrors;

			doMicrosoftOAuth()
				.then(
					function() {
						testConnection(form)
							.then(
								function (result) {
									if (!result.success || !result.testSuccessful)
										return;

									$scope.account.oauthCode = null;
									$scope.account.oauthAccount = result.oauthAccount;
									$scope.account.oauthAccessToken = result.oauthAccessToken;
									$scope.account.oauthRefreshToken = result.oauthRefreshToken;
									$scope.account.oauthTokenExpires = result.oauthTokenExpires;

									$scope.testingConnection = true;
									$scope.startingMigration = true;
									externalEmailService.startMigration($scope.account)
										.then(
											function () {
												$mdDialog.hide();
											},
											errorHandling.report)
										.finally(function () {
											$scope.testingConnection = false;
										});
								},
								function() {});
					},
					errorHandling.report);
		}

		function testConnection(form) {
			if (!form.$valid)
				$q.when();

			const defer = $q.defer();

			$scope.testFailure = null;
			$scope.testingConnection = true;
			$scope.testSuccess = false;

			$q.all([
				externalEmailService.testConnection($scope.account, $scope.isMigration),
				minTimePromise()
			])
				.then(onSuccess, onFailure);

			return defer.promise;

			function minTimePromise() {
				var deferred = $q.defer();
				$timeout(deferred.resolve, 500);
				return deferred.promise;
			}

			function onFailure(result) {
				$scope.testingConnection = false;
				$scope.testFailure = $translate.instant(result.data.message || "CONNECTION_TEST_UNABLE_TO_CONNECT");
				$scope.sslAuthenticationFailed = result.sslAuthenticationFailed;

				defer.reject();
			}

			function onSuccess(results) {
				$scope.testingConnection = false;
				$scope.testSuccess = results[0].data.testSuccessful;
				$scope.mustTestConnection = !$scope.testSuccess;
				$scope.testFailure = results[0].data.testSuccessful
					? null
					: $translate.instant(results[0].data.message || ("CONNECTION_TEST_UNABLE_TO_CONNECT"));

				if (!$scope.testSuccess)
					defer.reject();

				successHandling.report();

				defer.resolve(results[0].data);
			}
		}
	}
})();