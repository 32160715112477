(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataMail", coreDataMail);

    function coreDataMail($rootScope, $http, $q, $filter, preferencesStorage, coreDataSettings, browserNotifications, userDataService, userTimeService, folderNameTranslator, apiCategories) {
        var _disposableAddress = undefined;
        var disposableLoaded = false;
        var previousTreeController;
        var inboxUnreadCount = 0;
        var resources = {};
        var disposableAddress = {};
        var mailTree = {
            data: [],	// Used by tree directive
            map: [],	// Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {},
            get AmIOwner() {
                return mailTree.selectedBranchData && mailTree.selectedBranchData.owner === userDataService.user.emailAddress;
            }
        };
        var shortMailTree = {
            data: [],	// Used by tree directive
            map: [],	// Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        };
        var mailList = {
            params: {},
            loadingMailData: false,
            get sortType() { return preferencesStorage.getSessionSortingFilteringParam("email", "sortType"); },
            set sortType(value) { preferencesStorage.setSessionSortingFilteringParam("email", "sortType", value); },
            get sortDirection() { return preferencesStorage.getSessionSortingFilteringParam("email", "sortDirection"); },
            set sortDirection(value) { preferencesStorage.setSessionSortingFilteringParam("email", "sortDirection", value); },
            get sortAscending() { return preferencesStorage.getSessionSortingFilteringParam("email", "sortAscending"); },
            set sortAscending(value) { preferencesStorage.setSessionSortingFilteringParam("email", "sortAscending", value); },
            get filterType() { return preferencesStorage.getSessionSortingFilteringParam("email", "filterType"); },
            set filterType(value) { preferencesStorage.setSessionSortingFilteringParam("email", "filterType", value); },
            get filterValue() {
                var value = preferencesStorage.getSessionSortingFilteringParam("email", "filterValue");
                if (value === undefined) {
                    value = true;
                    preferencesStorage.setSessionSortingFilteringParam("email", "filterValue", value);
                }
                return value;
            },
            set filterValue(value) {
                preferencesStorage.setSessionSortingFilteringParam("email", "filterValue", value);
            },
            get categoryFilters() {
	            const catFilter = apiCategories.getCategoryFilter("email");
	            return !catFilter ? undefined : catFilter.categoryData;
            },
            //set categoryFilters(value) { preferencesStorage.setSessionSortingFilteringParam("email", "categoryFilters", value); },
            get hasCategoryFilter() {
                return !apiCategories.getCategoryFilter("email").allSelected();
            }
        };

        // Public Variables
        var vm = this;
        vm.reloadMailTreeOnEnter = false;
        vm.baseUrl = window.location.href.substring(0, window.location.href.indexOf("/interface/root#"));
        vm.searchText = "";
        vm.selectedEmailUids = [];
        vm.expansionList = [];
        vm.Data = {
            get disposableAddress() { return getDisposableAddress(); },
            set disposableAddress(value) { setDisposableAddress(value); }
        };
        vm.ignoreFolderUpdate = {
            requested: Date.now(),
            ignored: Date.now()
        }

        // Functions
        vm.addMailItem = addMailItem;
        vm.expandBranches = expandBranches;
        vm.formatMessageDate = formatMessageDate;
        vm.getFolderList = getFolderList;
        vm.getSharedFolderList = getSharedFolderList;
        vm.getMailList = getMailList;
        vm.getMailTree = getMailTree;
        vm.getShortMailTree = getShortMailTree;
        vm.getUnreadCount = getUnreadCount;
        vm.init = init;
        vm.loadMailTree = loadMailTree;
        vm.modifiedMailItem = modifiedMailItem;
        vm.removedMailItem = removedMailItem;
        vm.reset = reset;
        vm.resyncFolderUnreadCount = resyncFolderUnreadCount;
        vm.sortFolders = sortFolders;
        var loadDisposableAddressDebounced = _.debounce(loadDisposableAddress, 50, true);

        // Startup
        activate();

        ///////////////////////////////////////////////////////

        function activate() {
            vm.lastSelectedBranchData =
                preferencesStorage.getSessionSortingFilteringParam("email", "treeSelectedBranchData") ||
                preferencesStorage.getSessionSortingFilteringParam("email", "shortTreeSelectedBranchData") ||
                {};
            $rootScope.$on("disposableAddressModified", onDisposableAddressModified);
        }

        function init() {
            userTimeService.init();

            $rootScope.$on('signalR.mailHub.client.contentFilterProgress', contentFilterProgress);
            return vm.resyncFolderUnreadCount('inbox');
        }

        function reset() {
            vm.searchText = "";
            vm.selectedEmailUids = [];
            previousTreeController = undefined;
            mailTree.data.length = 0;
            mailTree.map.length = 0;
            mailTree.selectedBranchData = {};
            shortMailTree.data.length = 0;
            shortMailTree.map.length = 0;
            shortMailTree.selectedBranchData = {};
            mailList.params = {};
            _disposableAddress = undefined;
        }

        function loadMailTree(treeController, forceReload) {
            var defer = $q.defer();
            vm.reloadMailTreeOnEnter = false;
            if ((treeController == undefined) && previousTreeController)
                treeController = previousTreeController;

            if (forceReload || mailTree.data.length === 0) {
                loadMailTreeData(defer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            } else {
                var b = mailTree.map[mailTree.selectedBranchData.key];
                if (!b) { b = shortMailTree.map[mailTree.selectedBranchData.key] }
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                        treeController.select_branch(b);
                        vm.expandBranches();
                    }
                    defer.resolve(false);
                }
                defer.resolve(true);
            }
            return defer.promise;
        };

        function loadMailTreeData(defer, treeController) {
            var promises = [
                $http.get("~/api/v1/folders/list-email-folders"),
                coreDataSettings.settingsData.resources,
                vm.Data.disposableAddress
            ];
            $q.all(promises)
                .then(function (responses) {
                    var response = responses[0];
                    resources = responses[1];
                    disposableAddress = responses[2];

                    //Load tree recursively
                    mailTree.data = [];
                    mailTree.map = [];
                    shortMailTree.data = [];
                    shortMailTree.map = [];
                    var inboxBranch = undefined;
                    for (var i = 0; i < response.data.folderList.length; ++i) {
                        var val = response.data.folderList[i];
                        var shortBranch = loadMailTreeBranch(val, treeController, true)
                        if (shortBranch && !shortBranch.data.canEdit && (!shortBranch.data.isShared || shortBranch.data.isBeingShared)) {
                            if (shortBranch.data.path.toLowerCase() === "inbox" && (shortBranch.data.owner && shortBranch.data.owner === userDataService.user.emailAddress)) {
                                inboxBranch = shortBranch;
                            } else {
                                mapFolderChildren(shortBranch, true);
                                shortMailTree.data.push(shortBranch);
                            }
                        }
                    }

                    if (inboxBranch) {
                        mapFolderChildren(inboxBranch, true);
                        shortMailTree.data.unshift(inboxBranch);
                    }

                    var priorities = ["inbox", "deleted_items", "drafts", "junk_email", "sent_items"];
                    for (var i = 0; i < shortMailTree.data.length; i++) {
                        // We want the mandatory mail folders to always appear first and in a set order
                        var priority = priorities.indexOf(shortMailTree.data[i].label.toLowerCase());
                        if (priority !== -1)
                            shortMailTree.data[i].priority = priority;
                    }
                    shortMailTree.data = $filter('orderBy')(shortMailTree.data, 'priority');

                    vm.expansionList = preferencesStorage.getSortingFilteringParam('email', 'expandedBranches');
                    if (!vm.expansionList) { vm.expansionList = []; }

                    $.each(response.data.folderList, function (i, val) {
                        var branch = loadMailTreeBranch(val, treeController);
                        var index = -1;
                        for (var j = 0; j < shortMailTree.data.length; ++j) {
                            if (shortMailTree.data[j].data.key === branch.data.key) {
                                index = j;
                                break;
                            }
                        }
                        if (index === -1) {
                            mapFolderChildren(branch, false);
                            //mailTree.map[branch.data.key] = branch;
                            mailTree.data.push(branch);
                        }
                        //treeController.expand_branch
                    });
                    mailTree.data = sortFolders(mailTree.data);
                    defer.resolve(true);
                }, function (response) {
                    defer.reject(response);
                });
        }

        function loadMailTreeBranch(val, treeController, shortTree) {
            // Load data for this branch. Recursively call any child branches.
            if (val.isMappedFolder) {
                if (val.translatedName && val.translatedName.indexOf(" - ") > 0) {
                    var splitName = val.translatedName.split(" - ");
                    if (folderNameTranslator.isBuiltinFolder(splitName[1])) {
                        var translatedPart = $filter('translate')(splitName[1]) || val.displayPath;
                        if (splitName[1] != translatedPart) {
                            val.translatedName = splitName[0] + " - " + translatedPart;
                        }
                    }
                }
            }
            var name = val.translatedName != undefined ? $filter('translate')(val.translatedName) : val.name;
            if (val.translatedName != undefined)
                val.untranslatedName = val.translatedName;

            for (var i = 0; i < resources.length; i++) {
                if (resources[i].shareType === 1 && resources[i].permissions > 0 && resources[i].resourceName.toLowerCase() === val.path.toLowerCase() && val.ownerEmailAddress.toLowerCase() === userDataService.user.emailAddress.toLowerCase()) {
                    val.isBeingShared = true;
                    break;
                }
            }

            var branch = {
                label: name,
                data: {
                    "mid": val.id,
                    "id": val.name,
                    "count": (name === "DRAFTS" || name === "Drafts") ? val.totalMessages : val.unread,
                    "path": val.path,
                    "canDelete": val.canDelete,
                    "canEdit": val.canEdit,
                    "owner": val.ownerEmailAddress,
                    "access": val.access,
                    "key": (val.ownerEmailAddress + "|" + val.path.toLowerCase()),
                    "isShared": val.access != 0 || val.isBeingShared,
                    "isBeingShared": val.isBeingShared,
                    "isDisposable": userDataService.user.settings.features.enableDisposableAddresses && disposableAddress && disposableAddress.folder === val.path,
                    "deleteBehavior": val.deleteBehavior,
                    "label": name,
                    "untranslatedName": val.untranslatedName,
                    "unclickable": !val.id,
                    "isMappedFolder": !!val.isMappedFolder,
                    "isMappedSubfolder": !!val.isMappedSubfolder,
                    "unreadCount": val.unread || 0,
                    "totalMessages": val.totalMessages || 0
                }
            };

            //if (branch.data.id.toLowerCase() === "deleted items" || branch.data.id.toLowerCase() === "junk e-mail") {
            //	branch.data.count = 0;
            //}

            if (vm.expansionList.indexOf(branch.data.key) !== -1) {
                branch.expanded = true;
                //delete vm.expansionList[branch.data.key];
            } else {
                branch.expanded = false;
            }

            //if (!shortTree) {
            // Recursively load subfolders
            if (val.subFolders != undefined) {
                branch.children = [];
                $.each(val.subFolders, function (i, v) {
                    branch.children.push(loadMailTreeBranch(v, treeController, shortTree));
                });
            }
            //}
            return branch;
        }

        function mapFolderChildren(branch, shortTree) {
            if (shortTree) {
                shortMailTree.map[branch.data.key] = branch;
            } else {
                mailTree.map[branch.data.key] = branch;
            }
            if (branch.children) {
                branch.children = sortFolders(branch.children);
                for (var i = 0; i < branch.children.length; ++i) {
                    mapFolderChildren(branch.children[i], shortTree);
                }
            }
        };

        function sortFolders(folders) {
            return $filter('orderBy')(folders, getFolderSortValue, false, compareFolders);
        }

        function getFolderSortValue(value) { return value; }

        function compareFolders(v1, v2) {
            var v1Exists = v1 && v1.value && v1.value.data;
            var v2Exists = v2 && v2.value && v2.value.data;
            if (!v1Exists || !v2Exists)
                return 0;
            if (v1.value.data.id == "SHARED_WITH_ME")
                return 1;
            if (v2.value.data.id == "SHARED_WITH_ME")
                return -1;
            return v1.value.label.localeCompare(v2.value.label);
        };

        function expandBranches() {
            //reset all branches to not be expanded first.
            var keys = Object.keys(mailTree.map);
            var i;
            for (i = 0; i < keys.length; ++i) {
                if (mailTree.map[keys[i]] != undefined)
                    mailTree.map[keys[i]].expanded = false;
            }
            keys = Object.keys(shortMailTree.map);
            for (i = 0; i < keys.length; ++i) {
                shortMailTree.map[keys[i]].expanded = false;
            }

            vm.expansionList = preferencesStorage.getSortingFilteringParam('email', 'expandedBranches');
            if (!vm.expansionList) { vm.expansionList = []; }
            for (i = 0; i < vm.expansionList.length; ++i) {
                var e = vm.expansionList[i];
                var branch = mailTree.map[e];
                if (!branch) {
                    branch = shortMailTree.map[e];
                }
                if (branch) {
                    branch.expanded = true;
                }
            }
        }

        function getUnreadCount() {
            if (!mailTree || !shortMailTree) { return 0; }
            var inbox = {}
            if (mailTree.map[userDataService.user.emailAddress + "|inbox"]) {
                var inbox = mailTree.map[userDataService.user.emailAddress + "|inbox"];
            } else {
                var inbox = shortMailTree.map[userDataService.user.emailAddress + "|inbox"];
            }
            if (inbox && inbox.data && inbox.data.count)
                return inbox.data.count;
            else {
                return inboxUnreadCount;
            }
        }

        function getMailTree() {
            return mailTree;
        }

        function getShortMailTree() {
            return shortMailTree;
        }

        function getMailList() {
            return mailList;
        }

        function addMailItem(data) {
            if (window.location.href.indexOf('/popout/') != -1)
                return; //Don't process this for popouts

            var requestSet = [];
            var folders = [];
            for (var i = 0; i < data.length; ++i) {
                var d = data[i];
                d.folder = d.folder.toLowerCase();
                if (d.isNew && d.folder == "inbox") {
                    requestSet.push(d);
                }
                if (!d.ownerEmailAddress) {
                    d.ownerEmailAddress = userDataService.user.emailAddress;
                }
                var set = d.ownerEmailAddress + "|" + d.folder.toLowerCase();
                if (folders.indexOf(set) == -1) {
                    folders.push(set);
                }
            }

            if (requestSet.length > 0) {
                var params = { messages: requestSet };
                $http.post("~/api/v1/mail/messagemetadata", params)
                    .then(function (response) {
                        if (!response.data.results) {
                            return;
                        }
                        for (var i = 0; i < response.data.results.length; ++i) {
                            var message = response.data.results[i];
                            message.folder = message.folder.toLowerCase();
                            if (message.ownerEmailAddress == undefined) { message.ownerEmailAddress = ""; }
                            browserNotifications.notifyOfNewMessage(message);
                        }
                    }, function (failure) { });
            }

            // Update folders' unread count
            for (var i = 0; i < folders.length; ++i) {
                var v = folders[i];
                var d = v.split("|");
                vm.resyncFolderUnreadCount(d[1], d[0]);
            }
        }

        function modifiedMailItem(data) {
            var folders = [];
            for (var i = 0; i < data.length; ++i) {
                var v = data[i];
                var set = v.ownerEmailAddress + "|" + v.folder.toLowerCase();
                if (folders.indexOf(set) == -1) {
                    folders.push(set);
                }
            }

            // Update folders' unread count
            for (var i = 0; i < folders.length; ++i) {
                var v = folders[i];
                var d = v.split("|");
                vm.resyncFolderUnreadCount(d[1], d[0]);
            }
        }

        function removedMailItem(data) {
            var folders = [];
            for (var i = 0; i < data.length; ++i) {
                var v = data[i];
                var set = v.ownerEmailAddress + "|" + v.folder.toLowerCase();
                if (folders.indexOf(set) == -1) {
                    folders.push(set);
                }
            }

            // Update folders' unread count
            for (var i = 0; i < folders.length; ++i) {
                var v = folders[i];
                var d = v.split("|");
                vm.resyncFolderUnreadCount(d[1], d[0]);
            }
        }

        function formatMessageDate(date, short) {
            var mo = userTimeService.convertLocalToUserTimeMoment(date);
            var isToday = mo.isSame(new Date(), 'day');
            if (short) {
                if (isToday) {
                    return $filter('date')(mo.toDate(), 'shortTime');
                } else {
                    return $filter('date')(mo.toDate(), 'shortDate');
                }
            } else {
                return $filter('date')(mo.toDate(), 'short');
            }
        }

        function contentFilterProgress(event, data) {
            if (data.done) {
                if (data.affectedFolders) {
                    for (var i = 0; i < data.affectedFolders.length; ++i) {
                        vm.resyncFolderUnreadCount(data.affectedFolders[i]);
                    }
                }
            }
        }

        function resyncFolderUnreadCount(folder, owner) {
            if (!owner)
                owner = userDataService.user.emailAddress;
            folder = folder.toLowerCase();
            //if (folder === 'junk e-mail' || folder === 'deleted items') { return; }
            var parameters = {
                'Folder': folder,
                'OwnerEmailAddress': owner,
            }
            return $http.post("~/api/v1/folders/folder", parameters)
                .then(function (response) {
                    var leaf = mailTree.map[parameters.OwnerEmailAddress + "|" + parameters.Folder];
                    var shortLeaf = shortMailTree.map[parameters.OwnerEmailAddress + "|" + parameters.Folder];
                    if (leaf != undefined) {
                        leaf.data.count = parameters.Folder === "drafts" ? response.data.folder.totalMessages : response.data.folder.unread;
                        leaf.data.totalMessages = response.data.folder.totalMessages;
                        leaf.data.unreadCount = response.data.folder.unread;
                    }
                    if (shortLeaf != undefined) {
                        shortLeaf.data.count = parameters.Folder === "drafts" ? response.data.folder.totalMessages : response.data.folder.unread;
                        shortLeaf.data.totalMessages = response.data.folder.totalMessages;
                        shortLeaf.data.unreadCount = response.data.folder.unread;
                    }
                    if (parameters.Folder === "inbox" && (parameters.OwnerEmailAddress === "" || parameters.OwnerEmailAddress === undefined || parameters.OwnerEmailAddress === userDataService.user.emailAddress)) {
                        inboxUnreadCount = response.data.folder.unread;
                        $rootScope.$broadcast('mail:unreadCountChanged');
                    }
                }, function (failure) { });
        }

        function getFolderList(parentFolder, currentPath) {
            var defer = $q.defer();
            var sharedPrefix = $filter('translate')("SHARED_WITH_ME");
            $http.get('~/api/v1/folders/list-email-folders')
                .then(function (success) {
                    var folderList = success.data.folderList;
                    var newFolderList = $.extend(true, [], folderList);

                    for (var i = 0; i < folderList.length; ++i) {
                        if (folderList[i].subFolders) {
                            loadRecursiveFolders(folderList[i], newFolderList);
                        }
                    }

                    var index = -1;
                    var priorities = ["inbox", "deleted items", "drafts", "junk e-mail", "sent items"];
                    for (var i = 0; i < newFolderList.length; ++i) {

                        // We want the mandatory mail folders to always appear first and in a set order
                        var priority = priorities.indexOf(newFolderList[i].path.toLowerCase());
                        if (priority !== -1 && !newFolderList[i].isMappedFolder && !newFolderList[i].isMappedSubfolder) {
                            newFolderList[i].priority = priority;
                        } else if (newFolderList[i].name.startsWith("SHARED_WITH_ME")) {
                            newFolderList[i].sortName = String.fromCharCode(255) + newFolderList[i].name;
                        } else if (newFolderList[i].ownerEmailAddress === "") {
                            newFolderList[i].sortName = newFolderList[i].path;
                        } else {
                            newFolderList[i].sortName = newFolderList[i].translatedName || newFolderList[i].name;
                        }
                        if (newFolderList[i].path == parentFolder) {
                            index = i;
                        }
                    }
                    

                    newFolderList = $filter('orderBy')(newFolderList, ['priority', function (v1) { return v1.name.startsWith(sharedPrefix) ? 1 : 0; }, 'sortName']);

                    if (index != -1) {
                        newFolderList.splice(index, 1);
                    }

                    if (parentFolder && currentPath) {
                        newFolderList = $.grep(newFolderList, function (f) { return f.path.indexOf(currentPath) === -1 && f.path !== parentFolder });
                        newFolderList.unshift({ name: "", path: "", displayPath: "", translatedName: ('- ' + $filter('translate')("ROOT_FOLDER") + ' -') });
                    } else if (currentPath) {
                        newFolderList = $.grep(newFolderList, function (f) {
                            if (f.path === currentPath) 
                                return false;
                            
                            let charAfterPathIdx =  f.path.indexOf(currentPath) === -1 ? -1 : f.path.indexOf(currentPath) + currentPath.length;
                            return !(f.path.indexOf(currentPath) === -1 && f.path.charAt(charAfterPathIdx) == "/");
                        });
                    }

                    if (!newFolderList || newFolderList.length === 0) {
                        defer.reject($filter('translate')('MAIL_ERRORS_NO_AVAILABLE_FOLDERS'));
                        return;
                    }
                    defer.resolve(newFolderList);
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        function getSharedFolderList(owner, parentFolder, currentPath) {
            var defer = $q.defer();
            var params = { email: owner };
            var sharedPrefix = $filter('translate')("SHARED_WITH_ME");
            $http.post('~/api/v1/folders/list-email-folder-shares', params)
                .then(function (success) {
                    var folderList = success.data.folderList;
                    var newFolderList = $.extend(true, [], folderList);

                    for (var i = 0; i < folderList.length; ++i) {
                        if (folderList[i].subFolders) {
                            loadRecursiveFolders(folderList[i], newFolderList);
                        }
                    }

                    var index = -1;
                    var priorities = ["inbox", "deleted items", "drafts", "junk e-mail", "sent items"];
                    for (var i = 0; i < newFolderList.length; ++i) {

                        // We want the mandatory mail folders to always appear first and in a set order
                        var priority = priorities.indexOf(newFolderList[i].path.toLowerCase());
                        if (priority !== -1 && !newFolderList[i].isMappedFolder && !newFolderList[i].isMappedSubfolder) {
                            newFolderList[i].priority = priority;
                        } else if (newFolderList[i].name.startsWith("SHARED_WITH_ME")) {
                            newFolderList[i].sortName = String.fromCharCode(255) + newFolderList[i].name;
                        } else if (newFolderList[i].ownerEmailAddress === "") {
                            newFolderList[i].sortName = newFolderList[i].path;
                        } else {
                            newFolderList[i].sortName = newFolderList[i].translatedName || newFolderList[i].name;
                        }
                        if (newFolderList[i].path == parentFolder) {
                            index = i;
                        }
                    }

                    newFolderList = $filter('orderBy')(newFolderList, ['priority', function (v1) { return v1.name.startsWith(sharedPrefix) ? 1 : 0; }, 'sortName']);

                    if (index != -1) {
                        newFolderList.splice(index, 1);
                    }

                    if (parentFolder && currentPath) {
                        newFolderList = $.grep(newFolderList, function (f) { return f.path.indexOf(currentPath) === -1 && f.path !== parentFolder });
                        newFolderList.unshift({ name: "", path: "", translatedName: ('- ' + $filter('translate')("ROOT_FOLDER") + ' -') });
                    } else if (currentPath) {
                        newFolderList = $.grep(newFolderList, function (f) { return f.path.indexOf(currentPath) === -1 });
                    }

                    if (!newFolderList || newFolderList.length === 0) {
                        defer.reject($filter('translate')('MAIL_ERRORS_NO_AVAILABLE_FOLDERS'));
                        return;
                    }
                    defer.resolve(newFolderList);
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        function loadRecursiveFolders(folder, retarr) {
            for (var i = 0; i < folder.subFolders.length; ++i) {
                var f = folder.subFolders[i];
                var index = 0;
                for (var j = 0; j < retarr.length; ++j) {
                    if (retarr[j].path === folder.path) { index = j; break; }
                }
                if (!index) { index = retarr.length - 1; };
                if (folder.name === 'SHARED_WITH_ME') {
                    f.name =  $filter("translate")(folder.name) + '/' + f.name;
                    f.sortName = String.fromCharCode(255) + f.name;
                } else {
                    f.name = folder.name + '/' + f.name;
                }
                //f.name = (folder.name === 'SHARED_WITH_ME' ? $filter("translate")(folder.name) : folder.name )+ '\\' + f.name;
                retarr.splice(index + 1, 0, f);
                if (f.subFolders) {
                    loadRecursiveFolders(f, retarr);
                }
            }
            return retarr;
        };

        function onDisposableAddressModified() {
            getDisposableAddress(true);
        }

        function loadDisposableAddress() {
            return $http.get('~/api/v1/mail/disposable-address')
                .then(function (success) {
                    if (success.data.success)
                        _disposableAddress = {
                            address: success.data.alias.address,
                            expireTime: moment(success.data.alias.disposeExpireTime),
                            folder: success.data.alias.folder.toLowerCase()
                        };
                    else
                        _disposableAddress = undefined;
                }, function (failure) {
                    _disposableAddress = undefined;
                });
        };

        function getDisposableAddress(forceLoad) {
            var defer = $q.defer();
            if (disposableLoaded && !forceLoad)  {
                if (_disposableAddress && _disposableAddress.expireTime < moment()) {
                    _disposableAddress = undefined;
                    $rootScope.$broadcast("disposableAddress:changed");
                }
                return $q.when(_disposableAddress);
            }

            (forceLoad ? loadDisposableAddress : loadDisposableAddressDebounced)()
                .then(function (success) {
                    disposableLoaded = true;
                    defer.resolve(_disposableAddress);
                    $rootScope.$broadcast("disposableAddress:changed");
                }, function (failure) {
                    defer.resolve(_disposableAddress);
                });
            return defer.promise;
        };

        function setDisposableAddress(value) {
            if (!value) _disposableAddress = undefined
            else _disposableAddress = value;
            $rootScope.$broadcast("disposableAddress:changed");
        };
    }
})();
